import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isEmpty from 'lodash/fp/isEmpty';
import { useModal } from '@sumup/circuit-ui';

import PosterImage from './components/PosterImage';

import Video from '~/shared/components/Video';
import VideoModal from '~/shared/components/VideoModal';
import AspectRatio from '~/shared/components/AspectRatio';
import SiteContext from '~/shared/providers/SiteContext';

const ASPECT_RATIO_SIXTEEN_BY_NINE = 1.78;

const aspectRatioStyles = css`
  position: relative;
`;

const AspectRatioStyled = styled(AspectRatio)(aspectRatioStyles);

const alignToParentStyles = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
`;

const AlignToParent = styled('div')(alignToParentStyles);

/**
 * Video which supports a Video poster
 */
const PosterVideo = ({
  posterImage,
  showPlayButton = true,
  openInModal = false,
  alignToParent = false,
  video = {},
  posterWidth = 1200,
  ...rest
}) => {
  const { setModal } = useModal();
  const [showPosterImage, setShowPosterImage] = useState(false);
  const aspectRatio = rest.aspectRatio || ASPECT_RATIO_SIXTEEN_BY_NINE;
  const posterImageUrl = posterImage && posterImage.url;
  const { accessibilityLabels = {} } = useContext(SiteContext);

  const onPosterImageClick = () => {
    if (rest.onPosterImageClick) {
      rest.onPosterImageClick();
    }

    if (!openInModal) {
      setShowPosterImage(false);
      return;
    }

    setModal({
      className: {},
      children: ({ onClose }) => (
        <VideoModal onClose={onClose}>
          <Video {...video} autoplay={true} aspectRatio={aspectRatio} />
        </VideoModal>
      ),
      closeButtonLabel: accessibilityLabels.closeButton,
    });
  };

  useEffect(() => {
    if (posterImageUrl) {
      setShowPosterImage(true);
    }
  }, [posterImageUrl]);

  if (isEmpty(video)) {
    return null;
  }

  const content = (
    <span>
      {showPosterImage && (
        <PosterImage
          {...posterImage}
          showPlayButton={showPlayButton}
          onClick={onPosterImageClick}
          width={posterWidth}
        />
      )}
      {!showPosterImage && !openInModal && (
        <Video
          {...video}
          autoplay={!!posterImage?.url}
          aspectRatio={aspectRatio}
          alignToParent={alignToParent}
        />
      )}
    </span>
  );

  return alignToParent ? (
    <AlignToParent>{content}</AlignToParent>
  ) : (
    <AspectRatioStyled aspectRatio={aspectRatio}>{content}</AspectRatioStyled>
  );
};

PosterVideo.propTypes = {
  video: PropTypes.object,
  /**
   * Image that shows before actual video is shown
   */
  posterImage: PropTypes.shape(PosterImage.propTypes),
  /**
   * Show play button when poster image is set
   */
  showPlayButton: PropTypes.bool,
  /**
   * Open Video in Modal after click
   */
  openInModal: PropTypes.bool,
  /**
   * Align poster image to parent
   */
  alignToParent: PropTypes.bool,
  /**
   * Send interrupt signal down to component
   */
  doInterrupt: PropTypes.bool,
  /**
   * Set videos aspect ratio
   */
  aspectRatio: PropTypes.number,
  /**
   * Width of poster image for contentful
   */
  posterWidth: PropTypes.number,
  /**
   * Callback when the poster image was clicked
   */
  onPosterImageClick: PropTypes.func,
};

/**
 * @component
 */
export default PosterVideo;
