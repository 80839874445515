import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Col } from '@sumup/circuit-ui/legacy';

const baseStyles = ({ theme }) => css`
  ${theme.mq.mega} {
    padding-right: ${theme.spacings.tera};
    padding-left: ${theme.spacings.tera};
  }

  ${theme.mq.giga} {
    padding-right: ${theme.spacings.exa};
    padding-left: ${theme.spacings.exa};
  }

  ${theme.mq.tera} {
    padding-right: 0;
    padding-left: 0;
  }
`;

/**
 * Display the content at full-width with small spacing on the sides.
 */
const FullWidth = styled(Col)(baseStyles);

FullWidth.defaultProps = {
  span: '12',
};

/**
 * @component
 */
export default FullWidth;
