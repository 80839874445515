import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import { Body } from '@sumup/circuit-ui';
import { spacing, Col, Row, Grid } from '@sumup/circuit-ui/legacy';

import BackgroundImage from '../components/BackgroundImage';
import BackgroundVideo from '../components/BackgroundVideo';
import DynamicHeading from '../components/DynamicHeading';
import Badge from '../components/Badge';
import * as HeroService from '../HeroService';

import useWindowSize from '~/shared/hooks/use-window-size';
import RequestContext from '~/shared/providers/RequestContext';
import { richTextPropType } from '~/shared/util/shared-prop-types';
import FullWidth from '~/shared/components/FullWidth';
import LegacyButtonGroup from '~/shared/components/LegacyButtonGroup';
import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';
import dataSelector from '~/shared/util/data-selector';
import useMeasurePerformance from '~/shared/hooks/use-measure-performance';
import useIsLiteMode from '~/shared/hooks/use-is-lite-mode';
// eslint-disable-next-line max-len
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

const DATA_SELECTOR = 'hero_framed';

const contentColStyles = ({ theme }) => css`
  text-align: left;

  ${theme.mq.kilo} {
    text-align: center;
  }
`;

const ContentCol = styled(Col)(contentColStyles);

const mediaWrapperStyles = ({ theme }) => css`
  position: relative;
  height: 380px;
  border-radius: ${theme.borderRadius.tera};
  overflow: hidden;
  margin-top: ${theme.spacings.zetta};

  ${theme.mq.kilo} {
    height: 460px;
  }
`;

const MediaWrapper = styled('div')(mediaWrapperStyles);

/**
 * A component that displays the titles and buttons above full-screen media.
 */
function HeroFramed({
  pretitle = '',
  headline = '',
  subheadline = {},
  heroImageDesktop: originalHeroImageDesktop = {},
  heroImageTablet: originalHeroImageTablet = {},
  heroImageMobile: originalHeroImageMobile = {},
  heroVideo: originalHeroVideo = {},
  badge: originalBadge,
  index,
  className,
  contentType,
  name,
  id,
  ...buttonProps
}) {
  useMeasurePerformance('hero-framed-component-render');

  const { experiments } = useOptimizelyData();
  const heroImageDesktop = getVariationComponent(
    originalHeroImageDesktop,
    experiments,
  );
  const heroImageTablet = getVariationComponent(
    originalHeroImageTablet,
    experiments,
  );
  const heroImageMobile = getVariationComponent(
    originalHeroImageMobile,
    experiments,
  );
  const hasImage = heroImageDesktop || heroImageTablet || heroImageMobile;
  const heroImage = hasImage && {
    mobile: heroImageMobile?.image?.file?.url,
    tablet: heroImageTablet?.image?.file?.url,
    desktop: heroImageDesktop?.image?.file?.url,
    altText: heroImageDesktop?.altText,
  };
  const heroVideo = getVariationComponent(originalHeroVideo, experiments);
  const badge = getVariationComponent(originalBadge, experiments);

  const request = useContext(RequestContext);
  const windowSize = useWindowSize();
  const isLiteMode = useIsLiteMode();

  const showVideo = HeroService.shouldRenderVideo(
    heroVideo,
    windowSize.width,
    request.userAgent,
    isLiteMode,
  );
  const showImage = !isEmpty(heroImage) && !showVideo;

  const [pretitleTag, titleTag] = HeroService.getPretitleAndTitleHeadings(
    pretitle,
    headline,
    index,
  );

  return (
    <Fragment>
      <Grid
        data-selector={dataSelector('section', DATA_SELECTOR)}
        data-elbcontext="component:hero_framed"
      >
        <Row>
          <ContentCol
            span={{ default: 12, kilo: 10, mega: 8 }}
            skip={{ default: 0, kilo: 1, mega: 2 }}
          >
            {pretitle && (
              <Body
                as={pretitleTag}
                size="one"
                variant="highlight"
                css={spacing({ bottom: 'mega' })}
              >
                {pretitle}
              </Body>
            )}
            {headline && (
              <DynamicHeading as={titleTag} chars={headline.length}>
                {headline}
              </DynamicHeading>
            )}
            <RichText
              richText={subheadline}
              renderMark={inline.createRenderMark(inline.ONE)}
              renderNode={inline.createRenderNode(inline.ONE, {
                contentType,
                contentEntryName: name,
                contentEntryId: id,
              })}
            />
            <LegacyButtonGroup
              align={LegacyButtonGroup.CENTER}
              buttonDataSelector={dataSelector('button_primary', DATA_SELECTOR)}
              secondaryButtonDataSelector={dataSelector(
                'button_secondary',
                DATA_SELECTOR,
              )}
              trackingContentEntry={{
                contentType,
                contentEntryName: name,
                contentEntryId: id,
              }}
              {...buttonProps}
            />
          </ContentCol>
        </Row>
      </Grid>
      <FullWidth>
        <MediaWrapper>
          {badge && <Badge badge={badge} parentContentType={contentType} />}
          {showImage && <BackgroundImage {...heroImage} />}
          {showVideo && <BackgroundVideo {...heroVideo} />}
        </MediaWrapper>
      </FullWidth>
    </Fragment>
  );
}

HeroFramed.propTypes = {
  pretitle: PropTypes.string,
  headline: PropTypes.string.isRequired,
  subheadline: richTextPropType,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonTrackingId: PropTypes.string,
  buttonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonUrl: PropTypes.string,
  secondaryButtonTrackingId: PropTypes.string,
  secondaryButtonOptimizelyFullStackClickEvents: PropTypes.arrayOf(
    PropTypes.string,
  ),
  heroImage: PropTypes.object,
  heroVideo: PropTypes.object,
  badge: PropTypes.object,
  index: PropTypes.number,
  className: PropTypes.string,
  contentType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

/**
 * @component
 */
export default HeroFramed;
