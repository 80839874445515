import React from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/fp/values';
import get from 'lodash/fp/get';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  CONTENTFUL_LIGHT_IMAGE_QUALITY,
  CONTENTFUL_DEFAULT_IMAGE_QUALITY,
} from '~/shared/constants/contentful';
import Image from '~/shared/components/Image';

export const FILTERS = {
  NONE: 'none',
  LINEAR_GRADIENT: 'linear gradient',
};

const filterStyles = {
  [FILTERS.LINEAR_GRADIENT]: css`
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      height: 100%;
      width: 100%;
      background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.34) 0%,
        rgba(0, 0, 0, 0) 52.15%
      );
    }
  `,
};

const imageStyles = ({ backgroundSize }) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: ${backgroundSize};
      object-position: center top;
    }
  }
`;

const StyledImage = styled(Image)(imageStyles);

const wrapperStyles = ({ backgroundColor, filter }) => {
  const filtering = filterStyles[filter] || '';

  return css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    /* Fallback background color */
    background-color: ${backgroundColor};
    ${filtering}
  `;
};
const BackgroundImageWrapper = styled('div')(wrapperStyles);

/**
 * Background Image
 */
const BackgroundImage = ({
  url,
  alt,
  filter,
  children,
  className,
  width,
  color,
  isLiteMode,
  soloDesign = {},
}) => {
  if (!url) {
    return null;
  }

  const backgroundColor = get('backgroundColor', soloDesign) || color;
  const backgroundSize = get('backgroundSize', soloDesign) || 'cover';
  const imageWidth = get('imageWidth', soloDesign) || width;
  const contentful = {
    q: isLiteMode
      ? CONTENTFUL_LIGHT_IMAGE_QUALITY
      : CONTENTFUL_DEFAULT_IMAGE_QUALITY,
  };

  return (
    <BackgroundImageWrapper
      className={className}
      filter={filter}
      backgroundColor={backgroundColor}
    >
      <StyledImage
        src={url}
        alt={alt}
        srcSet={[
          {
            src: url,
            size: '1x',
            width: imageWidth,
          },
          {
            src: url,
            size: '2x',
            width: imageWidth * 2,
          },
        ]}
        width={imageWidth}
        backgroundSize={backgroundSize}
        contentful={contentful}
        lazyLoad={false}
      />
      {children}
    </BackgroundImageWrapper>
  );
};

BackgroundImage.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  /**
   * Background Color
   */
  color: PropTypes.string,
  /**
   * Overlay filters for background image
   */
  filter: PropTypes.oneOf(values(FILTERS)),
  /**
   * Used for image optimizations
   */
  isLiteMode: PropTypes.bool,

  /*
   * TEMPORARY EXPERIMENTAL SOLO STYLES EXTENSIONS!
   * added via https://sumupteam.atlassian.net/browse/SA-32606
   * TBD: will be removed or converted to proper features
   */
  soloDesign: PropTypes.object,
};

BackgroundImage.FILTERS = FILTERS;

/**
 * @component
 */
export default React.memo(
  BackgroundImage,
  (prevProps, nextProps) => prevProps.url === nextProps.url,
);
