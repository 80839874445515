import React, { createElement } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import { Headline } from '@sumup/circuit-ui';
import { Theme } from '@sumup/design-tokens';
import {
  Experience,
  ESRLoadingComponent,
} from '@ninetailed/experience.js-next';

import Section from './components/Section';
import * as SectionsService from './SectionsService';
import { SectionsProps, SectionsRendererProps } from './types';

import { mapExperiences } from '~/shared/services/ninetailed/shared';
import { NINETAILED_IS_ENABLED } from '~/shared/services/ninetailed/constants';

const localeNameStyles = ({ theme }: { theme?: Theme }) => css`
  margin-top: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.mega};
`;
const LocaleName = styled(Headline)(localeNameStyles);

/**
 * This renderer was introduced during the Ninetailed integration to make sure
 * that components are rendered with the correct content type and props when the
 * variation is displayed.
 */
const SectionsRenderer = ({
  components,
  omitH1,
  ...props
}: SectionsRendererProps): JSX.Element => {
  const {
    contentType,
    visibility,
    id,
    spacings,
    background,
    soloDesign,
    skipLazyHydration,
    index,
  } = props;

  const component = components[contentType];
  if (!component) {
    return null;
  }

  const componentProps = {
    ...props,
    index: omitH1 ? 1 : index,
  };

  return (
    <Section
      {...{
        spacings,
        background,
        contentType,
        id,
        index,
        hideOn: visibility,
        soloDesign,
        skipLazyHydration,
      }}
    >
      {props.locale && props.localeName ? (
        <LocaleName as="h3">
          {props.localeName} ({props.locale})
        </LocaleName>
      ) : null}
      {createElement(component as React.FC<typeof componentProps>, {
        ...componentProps,
      })}
    </Section>
  );
};

/**
 * Determines which sections are visible for the current viewport, standardizes
 * the background color and renders the corresponding component for each
 * section, wrapped in the Section component.
 */
const Sections = ({
  sections: sectionsData = [],
  components = {},
  omitH1,
}: SectionsProps): JSX.Element => {
  if (isEmpty(sectionsData) || isEmpty(components)) {
    return null;
  }

  const sections = SectionsService.getSections(sectionsData, components);

  return (
    <>
      {sections.map((section, index) => {
        const { id } = section;
        const key = `${id}${index}`;

        return NINETAILED_IS_ENABLED ? (
          <Experience
            key={key}
            {...section}
            id={id}
            component={SectionsRenderer}
            experiences={mapExperiences(section.nt_experiences)}
            loadingComponent={ESRLoadingComponent}
            passthroughProps={{ index, key, omitH1, components }}
          />
        ) : (
          <SectionsRenderer
            key={key}
            index={index}
            {...section}
            omitH1={omitH1}
            components={components}
          />
        );
      })}
    </>
  );
};

export default Sections;
