import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const modalWrapperStyles = ({ theme }) => css`
  padding: 0;
  overflow: hidden;
  outline: none;

  ${theme.mq.untilKilo} {
    margin-top: 30vh;
  }

  ${theme.mq.kilo} {
    opacity: 1;
    margin: 10vh auto auto;
    max-height: 90vh;
    max-width: 90%;
    min-width: 450px;
    position: relative;
  }

  ${theme.mq.mega} {
    max-width: 750px;
  }

  ${theme.mq.giga} {
    max-width: 850px;
  }
`;

const StyledModalWrapper = styled('div')(modalWrapperStyles);

/**
 * Open links to videos in a modal overlay.
 */
function VideoModal({ children }) {
  return <StyledModalWrapper>{children}</StyledModalWrapper>;
}

VideoModal.propTypes = {
  children: PropTypes.object,
};

/**
 * @component
 */
export default VideoModal;
