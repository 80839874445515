import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import PlaySvg from './svg/play.svg';

import dataSelector from '~/shared/util/data-selector';

const DATA_SELECTOR = 'play_button';

const baseStyles = ({ theme }) => css`
  flex: 0 1 100%;
  padding-top: ${theme.spacings.giga};
  margin: auto;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
`;

const Button = styled('button')(baseStyles);

/**
 * Play Button
 */
const PlayButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    data-selector={dataSelector('button', DATA_SELECTOR)}
  >
    <PlaySvg />
  </Button>
);

PlayButton.propTypes = {
  onClick: PropTypes.func,
};

/**
 * @component
 */
export default PlayButton;
