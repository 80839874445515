import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import PlayButton from '../PlayButton';

import dataSelector from '~/shared/util/data-selector';
import BackgroundImage from '~/shared/components/BackgroundImage';

const DATA_SELECTOR = 'poster_image';

const baseStyles = ({ fallbackColor }) => css`
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${fallbackColor || 'var(--cui-bg-highlight)'};
`;

const Wrapper = styled(BackgroundImage)(baseStyles);

/**
 * Poster Image
 */
const PosterImage = ({
  url,
  alt,
  showPlayButton = true,
  onClick = () => {},
  width = 1200,
  fallbackColor,
}) => {
  if (!url) {
    return null;
  }

  return (
    <Wrapper
      data-selector={dataSelector('wrapper', DATA_SELECTOR)}
      fallbackColor={fallbackColor}
      width={width}
      url={url}
      alt={alt}
    >
      {showPlayButton && <PlayButton onClick={onClick} />}
    </Wrapper>
  );
};

PosterImage.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  showPlayButton: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.number,
  fallbackColor: PropTypes.string,
};

/**
 * @component
 */
export default PosterImage;
